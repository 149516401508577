import { getFrameworkVar } from '@sparky/framework';

import { PENDO_EVENT_MAX_SIZE } from '../constants/commonConstants';
import {
  APP_MODES,
  APP_VIEW_MODES,
  DEVICE_QUARANTINE_PARAMS,
} from '../constants/enums';
import { TechnicalReference } from '../types/types';

/**
 * replace all * with .*
 * If not present, add a / to the front of the string
 */
const convertToRegex = (patternString) =>
  new RegExp(
    '^' +
      patternString.replace(/(?<!\.)\*/g, '.*').replace(/^(?!\/)/, '/') +
      '$',
  );

const selectMaxLenElement = (arr, lenFn) => {
  let max = 0;
  let idx = 0;
  for (let i = 0; i < arr.length; i++) {
    if (lenFn(arr[i]) > max) {
      max = lenFn(arr[i]);
      idx = i;
    }
  }
  return arr[idx];
};

/**
 * Expand and remove duplicates
 */
export const expandTechnicalRefs = (
  list: TechnicalReference[],
  limit = list.length,
): TechnicalReference[] => {
  const technicalRefs = [] as TechnicalReference[];
  const lookup = {};
  for (let i = 0, lenI = list.length; i < lenI; i++) {
    const reference = list[i];
    //append subReferences that exist
    for (let j = 0, lenJ = reference.tech_ref_links.length; j < lenJ; j++) {
      const subReference = reference.tech_ref_links[j];
      /**
        Noticed can have duplicate title string, but with more granular url's

        ex. 'Command Center: Strata Cloud Manager'

        "https://docs.paloaltonetworks.com/strata-cloud-manager/getting-started/command-center#strata-command-center-operational-health"

        And

        "https://docs.paloaltonetworks.com/strata-cloud-manager/getting-started/command-center"

        The less granular one is sorted later so we can prevent pushing via lookup object
      */
      if (!lookup[subReference.title]) {
        technicalRefs.push({
          domain: reference.domain,
          tech_ref_links: [subReference],
        });
        lookup[subReference.title] = true;
      }
      if (technicalRefs.length >= limit) break;
    }
    if (technicalRefs.length >= limit) break;
  }
  return technicalRefs;
};

export function compareCuratedList(a, b, pathname) {
  /**
   * filter, then choose the item with the longest string for greatest specificity
   */
  const aItem = selectMaxLenElement(
    a.domains.filter((item) => convertToRegex(item.domain).test(pathname)),
    (item) => item?.domain?.length,
  );
  const bItem = selectMaxLenElement(
    b.domains.filter((item) => convertToRegex(item.domain).test(pathname)),
    (item) => item?.domain?.length,
  );
  const aRank = aItem?.rank ?? Infinity;
  const bRank = bItem?.rank ?? Infinity;

  //Rank in ascending order
  if (aRank < bRank) {
    //e.g a = 5 < b = 7, sort a before b
    return -1;
  } else if (aRank > bRank) {
    //a = 7 > b = 5, sort a after b
    return 1;
  }
  return 0;
}

export function compareTechnicalRefList(a, b, pathname) {
  const aMatchingLength = convertToRegex(a.domain).test(pathname)
    ? a.domain.length
    : 0;
  const bMatchingLength = convertToRegex(b.domain).test(pathname)
    ? b.domain.length
    : 0;
  //rank descending
  return bMatchingLength - aMatchingLength;
}

export function sortByPathname(data, pathname, compareFn) {
  const m_data = [...data];
  m_data.sort((a, b) => compareFn(a, b, pathname));
  return m_data;
}

export function mapColor(color) {
  switch (color) {
    case 'blue':
      return 'linear-gradient(135deg, #006FCC 0%, #004C9D 100%)';
    case 'black':
      return 'black';
    case 'white':
      return 'white';
    default:
      return 'black';
  }
}

export const todayFromToDates = () => {
  const currentDate = new Date();
  const todayDate = currentDate.toISOString().split('T')[0];
  return [`${todayDate} 00:00:00.001`, `${todayDate} 23:59:59.999`];
};

export const lastWeekFromToDates = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  const lastWeekDate = currentDate.toISOString().split('T')[0];

  // exclude current date
  const previousDay = new Date();
  previousDay.setDate(previousDay.getDate() - 1);
  const previousDate = previousDay.toISOString().split('T')[0];

  return [`${lastWeekDate} 00:00:00.001`, `${previousDate} 23:59:59.999`];
};

export const lastMonthFromToDates = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 30);
  const lastMonthDate = currentDate.toISOString().split('T')[0];

  const lastWeekMinusOneDayMore = new Date();
  lastWeekMinusOneDayMore.setDate(lastWeekMinusOneDayMore.getDate() - 8);
  const lastWeekPlusOneDayDate = lastWeekMinusOneDayMore
    .toISOString()
    .split('T')[0];

  return [
    `${lastMonthDate} 00:00:00.001`,
    `${lastWeekPlusOneDayDate} 23:59:59.999`,
  ];
};

export const lastMonthFromCurrentDates = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 30);
  const startDate = currentDate.toISOString().split('T')[0];

  const date = new Date();
  const endDate = date.toISOString().split('T')[0];

  return [`${startDate} 00:00:00.001`, `${endDate} 23:59:59.999`];
};

export const findValueByRegex = (data, keyword) => {
  for (const key in data) {
    const pattern = convertToRegex(key);
    if (pattern.test(keyword)) {
      return data[key];
    }
  }
  return null;
};

export const getEnvironment = () => {
  return process.env.REACT_APP_ENV || 'dev';
};

export const formatTextForHTML = (text) => {
  return text?.replace(/\n/g, '<br />');
};

export const isString = (value) => typeof value === 'string';

export const joinUrl = (base: string, path: string): string => {
  if (base.endsWith('/')) {
    base = base.slice(0, -1);
  }
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  return base + path;
};

export const convertTimestampSecondsToMilliseconds = (
  timestampInSeconds: number,
): number => timestampInSeconds * 1000;

export const isAppExpended = (viewMode: string): boolean => {
  return viewMode === APP_VIEW_MODES.EXPANDED;
};

export const isCSPAppMode = (appMode: string): boolean => {
  return appMode === APP_MODES.CSP_MODE;
};

export const isTSEAssistantAppMode = (appMode: string): boolean => {
  return appMode === APP_MODES.TSE_ASSISTANT_MODE;
};

/**
 * Chunks large data into smaller parts for Pendo analytics.
 *
 * If the data size is less than or equal to 350 bytes, it returns the data as a single chunk.
 * Otherwise, it breaks the data into smaller chunks of 350 bytes each.
 */
export const chunkLargeDataForPendo = (data) => {
  const maxChunkSize = PENDO_EVENT_MAX_SIZE;
  const jsonString: string = JSON.stringify(data);
  const sizeInBytes = new TextEncoder().encode(jsonString).length;

  if (sizeInBytes <= maxChunkSize) {
    return [jsonString];
  }

  const chunks: string[] = [];
  for (let i = 0; i < jsonString.length; i += maxChunkSize) {
    chunks.push(jsonString.slice(i, i + maxChunkSize));
  }

  return chunks;
};

export const isPDFMode = (): boolean => {
  return !!getFrameworkVar('pdf_mode');
};

export const extractValueForEntities = (entityName, value) => {
  return DEVICE_QUARANTINE_PARAMS.includes(entityName)
    ? value?.match(/\((\d+)\)/)?.[1]
    : value;
};

export const getTaskManagerIcon = (taskStatusData) => {
  const { failed, success, in_progress, unread } = taskStatusData;
  if (unread) {
    if (in_progress) {
      return 'TaskManagerInProgressIcon';
    } else if (failed) {
      return 'TaskManagerFailedIcon';
    } else if (success) {
      return 'TaskManagerSuccessIcon';
    } else {
      return 'TaskManagerIcon';
    }
  }
  return 'TaskManagerIcon';
};
