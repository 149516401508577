import { getMicroAppVars, getState, getCurrentTsgId } from '@sparky/framework';
import { isString } from './utils';

export const getFeatureFlag = (flag) => {
  let featureFlags = getMicroAppVars('netsec-co-pilot')?.featureFlags;
  if (isString(featureFlags)) {
    try {
      featureFlags = JSON.parse(featureFlags);
    } catch (error) {
      console.error('Error parsing feature flags:', error);
      featureFlags = {};
    }
  }

  if (featureFlags) {
    return featureFlags[flag];
  }
};

export const getMicroAppVariable = (variable) => {
  const variables = getMicroAppVars('netsec-co-pilot');
  return variables ? variables[variable] : undefined;
};

/**
 * Retrieves tenant information for Pendo.
 * @returns {Object} An object containing tenantId, subtenant, and fqdn.
 */
export const getTenantnfoForPendo = () => {
  const { saseIaGlobalState } = getState();
  const { subtenant = '' } = saseIaGlobalState ?? {};
  const tenantId = getCurrentTsgId() || '';
  return { tenantId, subtenant };
};
