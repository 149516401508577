import { getState } from '@sparky/framework';
import {
  SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  SPARKY_NAV3_ROUTES_EXTPOINT,
  SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
  SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
} from '@sparky/framework/extpoints';

import pkg from '../package.json';
import { getFeatureFlag } from './utils/microAppVariableUtils';

const init = async ({ sparkyFramework: { getFrameworkVars } }) => {
  console.info(
    `%c${pkg.name}: ${pkg.version}`,
    'font-size: 14px; color: white; background-color: black; border-radius: 8px; padding: 10px 15px;',
  );

  const { private_site_origin } = getFrameworkVars();

  import('@prisma-insights/charts').then(({ dynamicChartManager }) => {
    dynamicChartManager.setAccessToken(getState()?.libTokens?.mapBox);
  });

  return async function tsgLoad() {
    return {
      actions: [
        {
          // This can be accessed only by route - see hidden attribute below
          key: 'netsec-co-pilot-conversation',
          path: '/netsec-copilot/conversation',
          title: 'NetSec CoPilot chat',
          contentClassName: 'sparky-light-only panwds-tw3', // make it light theme only
          bypassTsgAvail: true, // allow user do not have any role to access this path
          bypassGlobalAvail: true, // allow user without any tsg access to this path
          noRBACheck: true,
          avail: () => true,
          exact: false,
          //contentClassName: 'panwds-tw3 sparky-dark-only',
          component: () =>
            import('../src/components/SingleConversationWrapper'),
          availFallbackRedirect: 'no-redirect',
          hidden: true, // Hide Nav item
        },
        {
          // sample URL
          //tse-assistant?caseNumber=02982902&tsgTenantId=1665278339&productType=ngfw&accountName=test&cdlTenantId=1977327585&reportTs=1714159341870
          key: 'tse-assistant',
          path: '/tse-assistant',
          title: 'TSE Assistant',
          icon: 'tse-assistant-icon',
          className: 'tse-assistant-page',
          noRBACheck: true,
          expandable: true,
          mergeNav: true,
          exact: false,
          avail: () => Boolean(private_site_origin), //control the show and hide of tse assistant only on AIOps for gcs env,
          callAvail: true,
          component: () => import('../src/pages/TSEAssistant'),
          availFallbackRedirect: 'no-redirect',
        },
        {
          key: 'embedded_copilot_csp',
          path: '/embedded/copilot/csp',
          contentClassName: 'sparky-light-only panwds-tw3 copilot-csp-page', // make it light theme only
          bypassTsgAvail: true, // allow user do not have any role to access this path
          bypassGlobalAvail: true, // allow user without any tsg access to this path
          hidden: true, // not show on left nav
          title: 'CSP CoPilot',
          noRBACheck: true,
          expandable: true,
          mergeNav: true,
          exact: false,
          component: () => import('../src/pages/CSPHostApp'),
          availFallbackRedirect: 'no-redirect',
        },
      ],
      exposes: {
        default: {
          CopilotIconButton: () =>
            getFeatureFlag('useCopilot2.0')
              ? import('./components/2.0/CopilotButtonIcon')
              : import('./components/CopilotButtonIcon'),
        },
        'scm_main_layout.content_right': {
          CopilotContextual: () =>
            getFeatureFlag('useCopilot2.0')
              ? import('./components/2.0/HostApp')
              : import('./pages/HostApp'),
        },
      },
    };
  };
};

// nav V3
init.extensions = ({ sparkyFramework, vars }) => {
  const { getFrameworkVars } = sparkyFramework;
  const { private_site_origin } = getFrameworkVars();

  const nav3LeftNavExt = {
    point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
    configs: [
      {
        id: 'sparky.nav.left.netsec.co_pilot',
        config: {
          navigation: () => [
            {
              key: 'tse-assistant',
              title: 'TSE Assistant',
              icon: 'tse-assistant-icon',
              path: '/tse-assistant',
              className: 'tse-assistant-page',
              avail: () => Boolean(private_site_origin),
              noRBACheck: true,
              expandable: true,
              mergeNav: true,
              exact: false,
              callAvail: true,
              availFallbackRedirect: 'no-redirect',
            },
          ],
        },
        isAllowed: () => true,
      },
    ],
  };

  const nav3routes = [
    {
      id: 'netsec.co_pilot.nav.routes.co_pilot_conversation',
      config: {
        key: 'netsec-co-pilot-conversation',
        path: '/netsec-copilot/conversation',
        menuLink: 'CONVERSATION',
        className: 'sparky-light-only panwds-tw3',
        avail: () => true,
        component: () => import('../src/components/SingleConversationWrapper'),
        availFallbackRedirect: 'no-redirect',
        noRBACheck: true,
        bypassTsgAvail: true,
        bypassGlobalAvail: true,
        exact: false,
      },
    },
    {
      id: 'netsec.co_pilot.nav.routes.co_pilot.tse_assistant',
      config: {
        key: 'tse-assistant',
        path: '/tse-assistant',
        menuLink: 'TSE_ASSISTANT',
        className: 'tse-assistant-page',
        avail: () => Boolean(private_site_origin),
        component: () => import('../src/pages/TSEAssistant'),
        availFallbackRedirect: 'no-redirect',
        noRBACheck: true,
        expandable: true,
        mergeNav: true,
        exact: false,
      },
    },
    {
      id: 'netsec.co_pilot.nav.routes.co_pilot_csp',
      config: {
        key: 'embedded_copilot_csp',
        path: '/embedded/copilot/csp',
        menuLink: 'CSP',
        className: 'sparky-light-only panwds-tw3 copilot-csp-page',
        title: 'CSP CoPilot',
        component: () => import('../src/pages/CSPHostApp'),
        availFallbackRedirect: 'no-redirect',
        noRBACheck: true,
        expandable: true,
        mergeNav: true,
        exact: false,
        bypassTsgAvail: true,
        bypassGlobalAvail: true,
      },
    },
  ];

  const nav3routeExt = {
    point: SPARKY_NAV3_ROUTES_EXTPOINT,
    configs: nav3routes.map((route) => ({
      ...route,
      config: {
        ...route.config,
        mapStateToProps: (props) => ({
          ...props,
          routes: [route],
          vars,
          frameworkVars: sparkyFramework.getFrameworkVars(),
        }),
      },
    })),
  };

  const exposesExt = {
    point: SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
    configs: [
      {
        id: 'netsec.co_pilot.exposes',
        config: {
          exposes: () => ({
            default: {
              CopilotIconButton: () => import('./components/CopilotButtonIcon'),
            },
            'scm_main_layout.content_right': {
              CopilotContextual: () => import('./pages/HostApp'),
            },
          }),
        },
      },
    ],
  };

  const stateExt = {
    point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
    configs: [
      {
        id: 'netsec.co_pilot.tsg.state',
        config: {
          name: 'co_pilot', // this we can use using getState().pai
          requireFetch: true, // true for use the fetch function
          // loader add the logic for profile and permissions
          loader: async (args) => {
            console.info(
              `%c${pkg.name}: ${pkg.version}`,
              'font-size: 14px; color: white; background-color: black; border-radius: 8px; padding: 10px 15px;',
            );
          },
        },
      },
    ],
  };

  return [nav3LeftNavExt, nav3routeExt, stateExt, exposesExt];
};

export default init;
